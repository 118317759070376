/*! ===================================
 *  Author: Nazarkin Roman, Egor Dankov
 *  -----------------------------------
 *  PuzzleThemes
 *  =================================== */

'use strict';

/***********************************************
 * Clients Slider
 ***********************************************/
(function ($) {

    $('.sp-slick-clients').slick({
        dots          : false,
        infinite      : true,
        adaptiveHeight: true,
        speed         : 750,
        slidesToShow  : 5,
        autoplay      : true,
        arrows        : false,
        responsive    : [{
            breakpoint: 992,
            settings  : {
                slidesToShow: 3
            }
        }, {
            breakpoint: 768,
            settings  : {
                slidesToShow: 2
            }
        }, {
            breakpoint: 480,
            settings  : {
                slidesToShow: 1
            }
        }]
    });

})(jQuery);



(function ($) {

    $('.sp-slick-demo').slick({
        dots          : false,
        infinite      : true,
        adaptiveHeight: true,
        speed         : 750,
        slidesToShow  : 3,
        autoplay      : true,
        arrows        : false,
        responsive    : [{
            breakpoint: 992,
            settings  : {
                slidesToShow: 3
            }
        }, {
            breakpoint: 768,
            settings  : {
                slidesToShow: 2
            }
        }, {
            breakpoint: 480,
            settings  : {
                slidesToShow: 1
            }
        }]
    });

})(jQuery);


/***********************************************
 * Testimonials Slider
 ***********************************************/
(function ($) {

    $('.sp-slick-testimonials').slick({
        dots          : true,
        infinite      : true,
        speed         : 750,
        slidesToShow  : 1,
        adaptiveHeight: true,
        autoplay      : true,
        arrows        : false,
        autoplaySpeed : 6500
    });

})(jQuery);


/***********************************************
 * "iMac" slider
 ***********************************************/
(function($) {

    $('.sp-slick-imac').slick({
        dots          : true,
        infinite      : true,
        speed         : 450,
        slidesToShow  : 1,
        adaptiveHeight: true,
        autoplay      : true,
        arrows        : false,
        fade          : true,
        easing        : 'linear'
    });

})(jQuery);


/***********************************************
 * Single Post Gallery
 ***********************************************/
(function ($) {

    $('.sp-slick-post-gallery').slick({
        dots          : false,
        infinite      : true,
        speed         : 750,
        slidesToShow  : 1,
        adaptiveHeight: true,
        autoplay      : true,
        autoplaySpeed : 5000,
        nextArrow     : '<div class="slick-next circle"><i class="icon-angle-right"></i></div>',
        prevArrow     : '<div class="slick-prev circle"><i class="icon-angle-left"></i></div>',
    });

})(jQuery);


/***************************************************
 * Add slick-animated class when transition is over
 ***************************************************/
(function ($) {

    var $sliders = $('.slick-initialized');

    $sliders.on('initialAnimate reInit afterChange', function (e, slick, currentSlide) {
        currentSlide = currentSlide || 0;
        slick = slick || $(this).slick('getSlick');

        $(slick.$slides).removeClass('slick-animated');
        $(slick.$slides[currentSlide]).addClass('slick-animated');
    });

    $sliders.trigger('initialAnimate');

})(jQuery);


/***************************************************
 * Stop sliders that is not currently in viewport
 ***************************************************/
(function ($) {

    var $sliders = $('.slick-initialized');

    PZTJS.scrollRAF($.throttle(250, function () {
        $sliders.each(function () {
            var $this = $(this),
                $slick = $this.slick('getSlick');

            // stop slider
            if (!PZTJS.isElementInViewport(this) && !$slick.paused) {
                $this.slick('pause');
            }

            // unpause slider
            if (PZTJS.isElementInViewport(this) && $slick.paused) {
                $this.slick('play');
            }
        });
    }));

})(jQuery);


/***************************************************
 * Integrate WOW.js with slick
 ***************************************************/
(function($) {

    $('.slick-initialized').on('beforeChange', function(e, slick, currentSlide, nextSlide) {
        $(slick.$slides[nextSlide]).find('.wow, .re-animate').each(function () {
            var el = $(this),
                newone = el.clone(true);

            el.before(newone);
            el.remove();
        });
    });

})(jQuery);